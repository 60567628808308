<template>
    <section class="layout">
        <SidebarLayout @change-view="changeView"></SidebarLayout>
        
        <main class="main-content">
            <NavigationLayout></NavigationLayout>

            <section class="view">
                <component :is="currentView"></component>
            </section>
        </main>
    </section>
</template>

<script>
import { markRaw } from 'vue';
import SidebarLayout from "./components/layout/SidebarLayout.vue";
import NavigationLayout from "./components/layout/NavigationLayout.vue";
import HomeView from "./components/views/HomeView.vue";

export default {
    name: "App",
    components: {
        SidebarLayout,
        NavigationLayout,
        HomeView: markRaw(HomeView),
    },
    data() {
        return {
            currentView: HomeView,
        };
    },
    created() {
        document.title = "PES Network Strategy";
    },
    methods: {
        changeView(view) {
            this.currentView = view;
        },
    }
};
</script>

<style lang="scss">
    @import "@/assets/styles/main.scss";
</style>

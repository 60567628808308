<template>
    <section class="home-view">


        <!-- Hero banner -->
        <div class="hero" style="margin-top: 64px">
            <div class="content-3">
                <div class="l">
                    <img
                        :src="require('@/assets/images/pes_log.png')"
                        loading="lazy"
                        width="253.28440856933594"
                        height="112"
                        alt=""
                        class="opengraph-logo-1200x630-1"
                    />
                    <div class="frame-10">
                        <div class="title-4">PES Network Strategy</div>
                        <div class="title-5">'It's better together!’</div>
                    </div>
                </div>
                <div class="r">
                    <div class="social">
                        <div class="share-this-post">Share publication</div>
                        <div class="share-buttons">
                            <div class="share-button">
                                <img
                                    :src="require('@/assets/svg/link-alt-2.svg')"
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    alt=""
                                    class="vectors-wrapper-5"
                                />
                            </div>
                            <div class="share-button">
                                <img
                                    :src="require('@/assets/svg/LinkedIn.svg')"
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    alt=""
                                    class="vectors-wrapper-5"
                                />
                            </div>
                            <div class="share-button">
                                <img
                                    :src="require('@/assets/svg/twitter.svg')"
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    alt=""
                                    class="vectors-wrapper-5"
                                />
                            </div>
                            <div class="share-button">
                                <img
                                    :src="require('@/assets/svg/facebook.svg')"
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    alt=""
                                    class="vectors-wrapper-5"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="links">
                        <div class="content-4">
                            <img
                                :src="require('@/assets/svg/plus-alt.svg')"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="vectors-wrapper-5"
                            />
                            <div class="link-5">Add to my publications</div>
                        </div>
                        <div class="content-4">
                            <img
                                :src="require('@/assets/svg/bell-plus-alt.svg')"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="vectors-wrapper-5"
                            />
                            <div class="link-5">Create alert</div>
                        </div>
                        <div class="content-4">
                            <img
                                :src="require('@/assets/svg/link.svg')"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="vectors-wrapper-5"
                            />
                            <div class="link-5">Permanent link</div>
                        </div>
                        <div class="content-4">
                            <img
                                :src="require('@/assets/svg/purchase-tag-alt.svg')"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="vectors-wrapper-5"
                            />
                            <div class="link-5">Metadata RDF</div>
                        </div>
                        <div class="content-4">
                            <img
                                :src="require('@/assets/svg/code.svg')"
                                loading="lazy"
                                width="24"
                                height="24"
                                alt=""
                                class="vectors-wrapper-5"
                            />
                            <div class="link-5">Embed</div>
                        </div>
                    </div>
                </div>
            </div>
            <img
                :src="require('@/assets/images/hero_banner.png')"
                loading="lazy"
                alt=""
                class="image"
            />
        </div>

        <!-- Contents -->
        <div class="contents">
            <!-- Text block -->
            <div class="section-3" id="foreword">
                <div class="txt-container-3">
                    <div class="frame-11">
                        <div class="subtitle-30">Foreword</div>
                        <div class="subtitle-31">'It's better together!’</div>
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32 subtitle-32">
                            <span class="subtitle-33"
                                >In the seven years since it was established,
                                the</span
                            ><span class="subtitle-34">&nbsp;</span>
                            <a
                                href="https://ec.europa.eu/social/main.jsp?catId=1100&amp;langId=en"
                                target="_blank"
                                class="subtitle-35"
                                >European Network of Public Employment
                                Services</a
                            ><span class="subtitle-33"
                                >&nbsp;(PES Network) has proven to be&nbsp;a
                                great success. By enabling cooperation between
                                services in Europe, it has delivered benefits
                                both for PES and the citizens they serve and
                                improved the operation of national and European
                                labour markets.</span
                            >
                        </div>
                    </div>
                    <div class="div-block">
                        <img
                            :src="require('@/assets/images/pes_1.png')"
                            loading="lazy"
                            alt=""
                            class="image-3"
                        />
                        <div class="text-12">
                            The PES Network is a European Network of 32 Public
                            Employment Services (PES) from 30 Member and Partner
                            States, and the European Commission.
                        </div>
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32">
                            <span class="subtitle-33"
                                >The decision of the European institutions in
                                2020 to&nbsp;</span
                            >
                            <a
                                href="https://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1606741261195&amp;uri=CELEX%3A32020D1782"
                                target="_blank"
                                class="subtitle-35"
                                >extend the mandate of the Network</a
                            ><span class="subtitle-33"
                                >&nbsp;has confirmed the importance of these
                                collective efforts. As the Network Board, we are
                                delighted to continue our joint activities. The
                                updated Network Strategy that follows is in
                                response both to this renewed mandate and the
                                rapidly changing European labour markets.</span
                            >
                        </div>
                    </div>
                    <div class="subtitle-33">
                        The Network's renewed mission is “to empower, educate,
                        and improve” our members in order “to promote
                        modernisation of individual PES”. By encouraging optimum
                        use of technology and data, and facilitating enhanced
                        competencies for PES staff, our goal is to enable
                        national PES to better fulfil their role as key actors
                        in the employment services ecosystem. By offering our
                        expertise and experience, we aim to contribute to
                        European employment strategies and services.
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32">
                            <span class="subtitle-33"
                                >More broadly, we hope to play a&nbsp;key role
                                in delivering on Europe's employment goals,
                                including the 2030 target - under the</span
                            ><span class="subtitle-34">&nbsp;</span>
                            <a
                                href="https://ec.europa.eu/info/strategy/priorities-2019-2024/economy-works-people/jobs-growth-and-investment/european-pillar-social-rights_en"
                                target="_blank"
                                class="subtitle-35"
                                >European Pillar of Social Rights</a
                            ><span class="subtitle-34">&nbsp;</span
                            ><span class="subtitle-33"
                                >- of a 78% employment rate. We also expect to
                                contribute to the implementation of the European
                                Green Deal and the UN Sustainable Development
                                Goals.</span
                            >
                        </div>
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32">
                            <span class="subtitle-33"
                                >Our updated Strategy emphasises that</span
                            ><span class="subtitle-34">&nbsp;</span>
                            <a
                                href="https://ec.europa.eu/social/main.jsp?langId=en&amp;catId=1100&amp;newsId=9980&amp;furtherNews=yes"
                                target="_blank"
                                class="subtitle-35"
                                >partnerships with other labour market actors</a
                            ><span class="subtitle-34">&nbsp;</span
                            ><span class="subtitle-33"
                                >will remain high on our agenda in the years
                                ahead. This reflects the evolution of the
                                employment services ecosystem and the increasing
                                relevance of partnerships for delivering on our
                                shared priorities, such as gender equality and
                                the integration of those furthest from the
                                labour market.</span
                            >
                        </div>
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32">
                            <span class="subtitle-33"
                                >Finally, we highlight the expected value of PES
                                Network outputs for&nbsp;national and European
                                policymakers and stakeholders. Our</span
                            ><span class="subtitle-34">&nbsp;</span>
                            <a
                                href="https://ec.europa.eu/social/main.jsp?catId=1163&amp;langId=en"
                                target="_blank"
                                class="subtitle-35"
                                >Knowledge Centre</a
                            ><span class="subtitle-34">&nbsp;</span
                            ><span class="subtitle-33">and</span
                            ><span class="subtitle-34">&nbsp;</span>
                            <a
                                href="https://ec.europa.eu/social/main.jsp?catId=1206&amp;langId=en"
                                target="_blank"
                                class="subtitle-35"
                                >PES Practices database</a
                            ><span class="subtitle-33"
                                >&nbsp;will continue to provide the information
                                and insights required to inform better
                                legislation and regulation. This includes
                                improving the design and delivery of active
                                labour market programmes.</span
                            >
                        </div>
                    </div>
                    <div class="subtitle-33">
                        We firmly believe that there are real opportunities for
                        national PES to benefit from the knowledge exchange,
                        mutual learning and policy expertise provided by the
                        European PES Network. We look forward to delivering on
                        this Strategy in the years ahead and invite you to join
                        us on this journey.
                    </div>
                    <div class="subtitle-32">
                        <div class="subtitle-32">
                            <span class="subtitle-33">The&nbsp;</span>
                            <a
                                href="https://www.pesnetwork.eu/the-network/board/"
                                target="_blank"
                                class="subtitle-35"
                                >PES Network Board</a
                            >
                        </div>
                    </div>
                </div>
            </div>

            <!-- Text block -->
            <div class="section-4" id="preface">
                <div class="txt-container-4">
                    <div class="subtitle-36">Preface</div>
                    <div class="subtitle-37">
                        This&nbsp;Strategy&nbsp;describes how the PES Network
                        can deliver on its mission.
                    </div>
                    <div class="ul-3">
                        <div class="subtitle-37">It sets out:</div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                what the PES Network is planning to achieve;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                what outputs will be produced;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                how and why these outputs will be used by
                                stakeholders, i.e., national PES and national
                                and European-level partners.
                            </div>
                        </div>
                    </div>
                    <div class="subtitle-37">
                        The rapidly changing world of work is placing increasing
                        demands on public employment services (PES).
                        Developments in technology offer labour market
                        opportunities that require new skills, while demand for
                        others can reduce or disappear. This is bringing about
                        significant changes for jobseekers, employers and PES.
                        Accelerated change and increased flexibility are driving
                        a shift away from traditional unemployment-employment
                        divisions towards the development of&nbsp;transitional
                        labour markets.
                    </div>
                </div>
                <div class="txt-container-4">
                    <div class="frame-12">
                        <img
                            :src="require('@/assets/images/pes_2.png')"
                            loading="lazy"
                            alt=""
                            class="image-2"
                        />
                        <div class="text-11">
                            The PES Network assists PES in dealing with a labour
                            market that is increasingly characterised by more
                            frequent transitions for citizens throughout their
                            working lives.
                        </div>
                    </div>
                    <div class="subtitle-37">
                        This results in far less stable employment relationships
                        and increasing numbers of job changes through a working
                        lifetime; it leads to the erosion of stable employment
                        and the growth of atypical employment patterns.
                        Furthermore, there is a growing trend towards increasing
                        labour market dualisation which puts a dividing line
                        between those with secure and less secure work. Where
                        PES acquire more knowledge on the implications of this
                        for their increasingly diverse customer base, they can
                        enhance activation. This will assist people in finding
                        decent work and seeking re/upskilling as required.
                    </div>
                    <div class="subtitle-37">
                        These changes to the world of work are inherent to
                        economic development, but risk having the greatest
                        impact on some of the most vulnerable people in society,
                        who are furthest from the labour market. New European
                        employment policies are being developed to address these
                        challenges.
                    </div>
                    <div class="subtitle-37">
                        PES have a key role in supporting citizens to manage
                        labour market risk through&nbsp;positive transitions.
                        They have developed a&nbsp;conducting role&nbsp;to
                        continue to support their core clients while addressing
                        the needs of new groups of customers, especially in
                        managing these transitions. Their aim is to optimise the
                        impact of labour market interventions.
                    </div>
                    <div class="ul-3">
                        <div class="subtitle-37">
                            The PES Network has been developed to assist PES in
                            achieving their objectives via a range of outputs:
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                identifying service offerings;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                encouraging innovation;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                sharing learning and exchange of good practice;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                setting and reviewing standards for service
                                delivery;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                encouraging the development of partnerships;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                facilitating Benchlearning (benchmarking +
                                mutual learning) to identify strengths and areas
                                for improvement in PES delivery, to inform
                                design of the annual PES work programme;
                            </div>
                        </div>
                        <div class="li-3">
                            <div class="text-10">•</div>
                            <div class="subtitle-37">
                                providing evidence to support policy design,
                                including through production of policy papers,
                                reports, and case studies of PES practices.
                            </div>
                        </div>
                    </div>
                    <div class="subtitle-37">
                        PES continue to be central labour market actors.
                        Fundamental changes in their operating environment means
                        they are increasingly delivering services as part of a
                        wider&nbsp;employment services ecosystem. In response to
                        the impact of technological developments, new models are
                        emerging for the recruitment of employees. For example,
                        employment applications are increasingly handled through
                        digital mediation platforms and enhanced analytics.
                    </div>
                    <div class="subtitle-37">
                        Though each PES operates within a specific national
                        institutional framework, with some country-specific
                        variations in their remit and delivery models, they are
                        all committed to modernising their services to achieve
                        the best possible outcomes for citizens. The various
                        approaches to organisational design and individual PES
                        responses to the challenges they face provide a broad
                        source of experience and expertise that is of great
                        collective benefit to the entire Network.
                    </div>
                    <div class="subtitle-37">
                        The employment services ecosystem enables&nbsp;PES and
                        their partners to function as a unit, providing
                        personalised support to meet individual circumstances.
                        Many organisations are available to provide specialist
                        support to people with complex needs so that they can
                        overcome barriers to labour market integration. The
                        employment services ecosystem takes advantage of the
                        potential opportunities offered by enhanced connectivity
                        between stakeholders. Thus, jobseekers are supported to
                        enter the labour market and employers to obtain the
                        right skills.
                    </div>
                    <div class="subtitle-37">
                        The Network therefore enables PES to&nbsp;review the
                        services they offer, their systems to deliver these, and
                        how they can best develop partnerships to meet the needs
                        of clients&nbsp;for increasingly personalised services.
                    </div>
                </div>
            </div>

            <!-- Text block -->
            <div
                class="frame-14"
                style="max-width: 1600px !important; width: 100% !important"
                id="strategic-trends"
            >
                <div class="section-hero">
                    <img
                        :src="require('@/assets/images/pes_3.png')"
                        alt=""
                        role="presentation"
                    />
                </div>
            </div>

            <div class="frame-14">
                <div class="section">
                    <div class="txt-container">
                        <div class="subtitle-5">
                            The intersection of these mega trends has
                            significant implications for PES, with greater:
                        </div>
                        <div class="ul">
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3 text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >empowerment</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– the growth of new markets
                                            requiring a revised employer
                                            engagement approach;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >polarisation</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– an increasing skill-set
                                            divide needing more emphasis on
                                            social inclusion;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >hyperconnectivity</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– integrating systems by
                                            embracing the employment services
                                            ecosystem;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >disengagement</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– interpersonal divergence
                                            and workforce fragmentation driving
                                            more of a focus on personalised
                                            support;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >ageing</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– creating a demand for
                                            inter-generational fairness with
                                            measures to combat age
                                            discrimination;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >dematerialisation</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– digitalisation driving the
                                            knowledge economy and society, with
                                            a data-driven approach becoming
                                            essential;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >scarcity</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– fostering the greening of
                                            the economy increasing the priority
                                            to promote green employment;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >blurring of boundaries</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– co-development of support
                                            systems is dismantling silos to
                                            enable customer-centric
                                            services;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >erosion of governance</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– increased decentralisation
                                            and diminishing trust in
                                            institutions is driving a transition
                                            from directing to enabling;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li">
                                <div class="text-2">•</div>
                                <div class="text-3">
                                    <div class="text-3">
                                        <span
                                            class="empowerment-the-growth-of-new-markets-requiring-a-revised-employer-engagement-approach-0"
                                            >displacement</span
                                        ><span class="subtitle-5"
                                            >&nbsp;– due to mass migration, with
                                            appropriate policy responses needed
                                            to ensure that the benefits from
                                            this can be realised.</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="subtitle-5">
                            The continued acceleration of social and
                            technological trends will require the Strategy to be
                            kept under review as a living document.
                        </div>
                        <div class="subtitle-5">
                            To support customers, the PES Network must navigate
                            these challenges and opportunities. It must keep up
                            to date with changes to identify promising practices
                            in national PES and promote these to assist PES in
                            adapting to a rapidly evolving situation. The
                            Network must focus on activities that can support
                            PES in dealing with the significant impact of labour
                            market disruption by identifying prospects for
                            positive intervention.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Text block -->
            <div
                class="frame-14"
                style="max-width: 1600px !important; width: 100% !important"
                id="pes-network"
            >
                <div class="section-hero">
                    <img
                        :src="require('@/assets/images/pes_4.png')"
                        alt=""
                        role="presentation"
                    />
                </div>
            </div>

            <div class="frame-14">
                <div class="section">
                    <div class="txt-container">
                        <div class="subtitle-8">
                            The revised Network vision is therefore intended to
                            set a&nbsp;strategic direction to focus on actions
                            necessary to respond to the changing and uncertain
                            environment. Of particular significance are
                            continuing, and increasingly frequent, changes in
                            the world of work and specifically the profound
                            disruption to labour markets generated by the
                            COVID-19 pandemic and the growth of the platform
                            economy.
                        </div>
                        <div class="subtitle-9">
                            <div class="subtitle-9 subtitle-9">
                                <span class="subtitle-8"
                                    >The revised legal text introduces new
                                    requirements for the Network to provide an
                                    EU-level instrument to stimulate national
                                    PES developments. These&nbsp;promote the
                                    increasing alignment of PES activities with
                                    EU policy priorities to meet new labour
                                    market challenges.&nbsp;Of particular
                                    relevance are references to the&nbsp;</span
                                >
                                <a
                                    href="https://ec.europa.eu/info/strategy/priorities-2019-2024/european-green-deal_en"
                                    target="_blank"
                                    class="subtitle-16"
                                    >European Green Deal</a
                                ><span class="subtitle-17">, the&nbsp;</span>
                                <a
                                    href="https://ec.europa.eu/info/strategy/priorities-2019-2024/economy-works-people/jobs-growth-and-investment/european-pillar-social-rights_en"
                                    target="_blank"
                                    class="subtitle-16"
                                    >European Pillar of Social Rights</a
                                ><span class="subtitle-8"
                                    >&nbsp;and the UN Sustainable Development
                                    Goals.</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="_3x-container">
                        <div class="subtitle-10">
                            EU 2030 Employment Targets
                        </div>
                        <div class="_3x employment-targets">
                            <div class="_1">
                                <div class="subtitle-11">At least</div>
                                <div class="subtitle-12">78%</div>
                                <div class="subtitle-13">
                                    <div class="subtitle-13 subtitle-13">
                                        <span class="subtitle-11"
                                            >aged 20 to 64 </span
                                        ><span class="subtitle-15"
                                            >in employment</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="_2">
                                <div class="subtitle-11">At least</div>
                                <div class="subtitle-14">½</div>
                                <div class="subtitle-15">
                                    the gender employment gap
                                </div>
                            </div>
                            <div class="_3">
                                <img
                                    :src="require('@/assets/svg/arrow_down.svg')"
                                    loading="lazy"
                                    width="91.589088439414"
                                    height="127.99974060058594"
                                    alt=""
                                    class="group-2"
                                />
                                <div class="subtitle-15">
                                    Decrease the rate of NEETs to 9%
                                </div>
                            </div>
                        </div>
                        <img
                            :src="require('@/assets/images/pes_5.png')"
                            loading="lazy"
                            width="612"
                            height="142"
                            alt=""
                            class="group-2"
                        />
                    </div>
                </div>
            </div>

            <!-- Text block -->
            <div
                class="frame-14"
                style="max-width: 1600px !important; width: 100% !important"
                id="vision-mission"
            >
                <div class="section-hero">
                    <img
                        :src="require('@/assets/images/pes_6.png')"
                        alt=""
                        role="presentation"
                    />
                </div>
            </div>

            <div class="frame-16">
                <div class="section-2">
                    <div class="txt-container-2 txt-container">
                        <div class="subtitle-28">
                            PES increasingly operate as part of
                            an&nbsp;employment service ecosystem. This is driven
                            by the need to increase outreach to vulnerable
                            citizens. It is facilitated by the potential to
                            utilise AI and big data to provide more personalised
                            support to all customers.
                        </div>
                        <div class="subtitle-28">
                            The&nbsp;COVID-19 pandemic has fast-tracked
                            changes&nbsp;that were already being driven by
                            existing trends.
                        </div>
                    </div>
                    <div class="txt-container-2">
                        <div class="subtitle-29">Vision</div>
                        <div class="subtitle-28">
                            The Network's vision is to be a&nbsp;vehicle for
                            enhanced European PES cooperation. This is to enable
                            national PES to deliver their role as key actors in
                            the employment services ecosystem and contribute to
                            European employment strategies and services. The
                            objective is to improve labour market function by
                            supporting the activities of jobseekers, employers,
                            and stakeholders. This is achieved by helping
                            citizens to successfully navigate transitions,
                            assisting employers with the recruitment of suitably
                            skilled staff, and working in partnership with
                            stakeholder organisations to achieve mutual
                            objectives. Priority will be given to actions
                            designed to overcome barriers to integration. The
                            Network also provides the opportunity for national
                            PES to capitalise on its role as a source of
                            significant employment policy expertise in the EU.
                        </div>
                    </div>
                    <div class="txt-container-2">
                        <div class="subtitle-29">Mission</div>
                        <div class="subtitle-28">
                            The mission of the Network is to&nbsp;empower,
                            educate, and improve&nbsp;so that it can achieve its
                            core purpose – to&nbsp;promote modernisation of
                            individual PES, encourage optimum use of technology
                            and data, and facilitate enhanced competencies for
                            staff. This is to assist PES in continuing to adapt
                            their role and function, thereby enabling them to
                            contribute to the implementation of EU employment
                            policies and strategies, and to provide efficient
                            services to jobseekers and employers.
                        </div>
                        <div class="frame-17">
                            <img
                                :src="require('@/assets/images/pes_7.png')"
                                loading="lazy"
                                width="968"
                                height="545"
                                alt=""
                                class="en_4-1-locked-aspect-ratio-1"
                            />
                            <div class="text-7">
                                The PES Network supports PES to enhance the
                                quality and modernisation of their services to
                                improve their resilience, notably through the
                                development of data-driven and personalised
                                services.
                            </div>
                        </div>
                        <div class="subtitle-28">
                            It will achieve this through continuing to
                            strengthen and encourage cooperation between PES.
                            This will include promoting the evolution of
                            services to enable successful transitions and meet
                            labour market challenges, thereby:
                        </div>
                        <div class="ul-2">
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9 text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >increasing capacity</span
                                        ><span class="subtitle-28"
                                            >&nbsp;to enhance labour market
                                            function;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >promoting labour market
                                            inclusion</span
                                        ><span class="subtitle-28"
                                            >&nbsp;by undertaking actions that
                                            foster gender equality and support
                                            for the integration of people from
                                            vulnerable groups;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >boosting labour demand</span
                                        ><span class="subtitle-28"
                                            >, including encouraging
                                            occupational and voluntary
                                            geographical mobility;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >facilitating the supply</span
                                        ><span class="subtitle-28"
                                            >&nbsp;of suitably skilled workers
                                            to promote employment growth;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >anticipating demands</span
                                        ><span class="subtitle-28"
                                            >,&nbsp;using labour market
                                            information and skills needs
                                            analysis;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >preparing the labour
                                            force&nbsp;</span
                                        ><span class="subtitle-28"
                                            >for active labour market
                                            participation and skills
                                            development, through training,
                                            career counselling and
                                            guidance;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >assisting jobseekers and
                                            employers</span
                                        ><span class="subtitle-28">
                                            in dealing with labour market
                                            crises, by providing PES with
                                            support to enable them to deliver
                                            services;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >supporting employers</span
                                        ><span class="subtitle-28"
                                            >&nbsp;to adapt to their changing
                                            role in an evolving labour
                                            market;</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="li-2">
                                <div class="text-8">•</div>
                                <div class="text-9">
                                    <div class="text-9">
                                        <span
                                            class="increasing-capacity-to-enhance-labour-market-function-2"
                                            >optimising benefits</span
                                        ><span class="subtitle-28"
                                            >&nbsp;from the evolution of the
                                            employment services ecosystem.</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "HomeView",
    props: {
        msg: String,
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>

<template>
    <aside id="main-sidebar" class="sidebar" :class="{'sidebar-open': isOpen}">
        <header>
            <img
                class="logo"
                :src="require('@/assets/images/eu-logo.png')"
                alt="EU logo"
            />

            <button class="sidebar-toggle" @click="toggleSidebar">
                <span :class="{'opened': isOpen}">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </button>
        </header>

        <div class="sidebar-content" :class="{'sidebar-open': isOpen}">
            <div class="inner-wrapper">
                <div class="contents-wrapper">

                    <img
                        class="logo"
                        :src="require('@/assets/images/eu-logo.png')"
                        alt="EU logo"
                    />
                    
                    <h1>PES Network Strategy</h1>

                    <form role="search">
                        <div class="search-input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z" fill="#0E47CB"/></svg>

                            <input
                                type="search"
                                id="search-input"
                                name="q"
                                placeholder="Search"
                                aria-label="Search"
                            />
                        </div>
                    </form>

                    <nav>
                        <h2>Contents:</h2>
                        <ul>
                            <li><a href="#" >Foreword</a></li>
                            <li><a href="#" >Preface</a></li>
                            <li><a href="#" data-target="strategic-trends">Strategic Trends - Opportunities and Challenges</a></li>
                            <li><a href="#" data-target="pes-network">PES Network Mandate</a></li>
                            <li><a href="#" data-target="vision-mission">Vision and Mission</a></li>
                            <li><a href="#" data-target="pes-objectives">PES Network Objectives and Purpose</a></li>
                            <li><a href="#" >PES Partnerships and the Employment Service Ecosystem</a></li>
                            <li><a href="#" >The Change Trajectory - Meeting Future Challenges</a></li>
                            <li><a href="#" >About</a></li>
                            <li><a href="#" >Getting in touch with the EU</a></li>
                        </ul>
                    </nav>
                </div>
                
                <footer>
                    <ul>
                        <li>
                            <a href="help-url">
                                <img :src="require('@/assets/svg/help-circle.svg')" alt="Help icon">
                                <span>Help</span>
                            </a>
                        </li>
                        <li>
                            <a href="download-url">
                                <img :src="require('@/assets/svg/file.svg')" alt="Download icon">
                                <span>Download PDF</span>
                            </a>
                        </li>
                        <li>
                            <a href="feedback-url">
                                <img :src="require('@/assets/svg/chat.svg')" alt="Chat bubbles icon">
                                <span>Give us feedback</span>
                            </a>
                        </li>
                    </ul>
                    <div class="language-selector">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon / globe"><path id="icon" d="M12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.514 2 12 2ZM19.931 11H17.167C17.0436 8.81124 16.4313 6.67797 15.375 4.757C16.5992 5.32905 17.6589 6.2014 18.4554 7.29291C19.252 8.38442 19.7596 9.65965 19.931 11ZM12.53 4.027C13.565 5.391 14.957 7.807 15.157 11H9.03C9.169 8.404 10.024 5.972 11.481 4.026C11.653 4.016 11.825 4 12 4C12.179 4 12.354 4.016 12.53 4.027ZM8.688 4.727C7.704 6.618 7.136 8.762 7.03 11H4.069C4.24177 9.64784 4.75663 8.3621 5.56489 7.26442C6.37315 6.16673 7.44808 5.29339 8.688 4.727ZM4.069 13H7.043C7.179 15.379 7.708 17.478 8.599 19.23C7.38119 18.6559 6.32773 17.7842 5.53596 16.6953C4.74419 15.6064 4.23966 14.3355 4.069 13ZM11.45 19.973C10.049 18.275 9.222 15.896 9.041 13H15.154C14.946 15.773 14.037 18.196 12.551 19.972C12.369 19.984 12.187 20 12 20C11.814 20 11.633 19.984 11.45 19.973ZM15.461 19.201C16.416 17.407 16.999 15.3 17.152 13H19.93C19.7612 14.3243 19.264 15.5853 18.4834 16.6684C17.7029 17.7514 16.6639 18.622 15.461 19.201Z" fill="#666666"/></g></svg>
                        <select>
                            <option value="en">English</option>
                            <option value="fr">Français</option>
                            <option value="de">Deutsch</option>
                            <option value="es">Español</option>
                            <option value="it">Italiano</option>
                            <option value="pt">Português</option>
                        </select>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M10.862 5.52863L7.99998 8.39063L5.13798 5.52863L4.19531 6.47129L7.99998 10.276L11.8046 6.47129L10.862 5.52863Z" fill="#666666"/></svg>
                    </div>
                </footer>
            </div>
        </div>

    </aside>
</template>

<script>
export default {
    name: "SidebarLayout",
    data() {
        return {
            currentView: 'HomeView',
            isOpen: false,
        };
    },
    methods: {
        switchView(view) {
            this.currentView = view;
            this.$emit('change-view', view);
            this.isOpen = false;

            const body = document.body;
            body.classList.remove('mobile-sidebar-open');
        },
        toggleSidebar() {
            this.isOpen = !this.isOpen;
            const body = document.body;
            if (this.isOpen) {
                body.classList.add('mobile-sidebar-open');
            } else {
                body.classList.remove('mobile-sidebar-open');
            }
        },
    },
    mounted() {
        const handle = this;
        const navLinks = document.querySelectorAll('nav a');
        const body = document.body;
        const sections = document.querySelectorAll('*[id]');
        const mainContent = document.querySelector('main.main-content');

        navLinks.forEach(link => {
            link.addEventListener('click', (event) => {
                event.preventDefault();

                const linkText = link.getAttribute('data-target') || link.textContent.trim().toLowerCase().replace(/\s+/g, '-');

                const targetElement = document.getElementById(linkText);
                
                if (targetElement) {
                    let nodePosition = targetElement.offsetTop; 

                    if (window.innerWidth >= 1200) {
                        let mainContentPosition;

                        if (mainContent.style.position === 'fixed') {
                            mainContentPosition = mainContent.offsetTop; 
                        } else {
                            mainContentPosition = mainContent.getBoundingClientRect().top + window.scrollY;
                        }

                        mainContent.scroll({
                            top: nodePosition - mainContentPosition - 300,
                            behavior: 'smooth'
                        });

                    } else {
                        window.scroll({
                            top: nodePosition - 150,
                            behavior: 'smooth'
                        });
                    }

                    handle.isOpen = false;
                    body.classList.remove('mobile-sidebar-open');
                }
            });
        });

        const setActiveNavLink = () => {
            let scrollPosition = 0;

            if (window.innerWidth >= 1200) {
                scrollPosition = mainContent.scrollTop + 400;
            } else {
                scrollPosition = window.scrollY + 400;
            }

            sections.forEach(section => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;
                const sectionId = section.getAttribute('id');

                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                    navLinks.forEach(link => {
                        const linkText = link.getAttribute('data-target') || link.textContent.trim().toLowerCase().replace(/\s+/g, '-');

                        link.classList.remove('active');
                        if (linkText === sectionId) {
                            link.classList.add('active');
                        }
                    });
                }
            });
        };

        if (window.innerWidth >= 1200) {
            mainContent.addEventListener('scroll', setActiveNavLink);
            
        } else {
            window.addEventListener('scroll', setActiveNavLink);
        }            
        
        setActiveNavLink();
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout/sidebar.scss";
</style>

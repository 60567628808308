<template>
    <header class="layout-navigation">
        <nav>
            <ul class="breadcrumb">
                <li class="responsive-dropdown">
                    ...
                </li>
                <li><a href="#home">European Commission</a></li>
                <li><a href="#publications">Publications</a></li>
                <li class="active" aria-current="page">
                    Towards a fair and sustainable Europe 2050
                </li>
            </ul>
            <div class="nav-icons">
                <a href="#accessibility" aria-label="Accessibility options">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M12 9C12.8284 9 13.5 8.32843 13.5 7.5C13.5 6.67157 12.8284 6 12 6C11.1716 6 10.5 6.67157 10.5 7.5C10.5 8.32843 11.1716 9 12 9Z" fill="#0E47CB"/><path d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z" fill="#0E47CB"/><path d="M16.5 10.5L16 9L13 10H11L8 9L7.5 10.5L10.5 11.5V13L9 17.25L10.5 18L11.75 14.5H12.25L13.5 18L15 17.25L13.5 13V11.5L16.5 10.5Z" fill="#0E47CB"/></svg>
                </a>
                <a href="#notifications" aria-label="Notifications">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M19 13.586V10C19 6.783 16.815 4.073 13.855 3.258C13.562 2.52 12.846 2 12 2C11.154 2 10.438 2.52 10.145 3.258C7.185 4.074 5 6.783 5 10V13.586L3.293 15.293C3.19996 15.3857 3.12617 15.4959 3.07589 15.6172C3.0256 15.7386 2.99981 15.8687 3 16V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V16C21.0002 15.8687 20.9744 15.7386 20.9241 15.6172C20.8738 15.4959 20.8 15.3857 20.707 15.293L19 13.586ZM19 17H5V16.414L6.707 14.707C6.80004 14.6143 6.87383 14.5041 6.92412 14.3828C6.9744 14.2614 7.00019 14.1313 7 14V10C7 7.243 9.243 5 12 5C14.757 5 17 7.243 17 10V14C17 14.266 17.105 14.52 17.293 14.707L19 16.414V17ZM12 22C12.6193 22.0008 13.2235 21.8086 13.7285 21.4502C14.2335 21.0917 14.6143 20.5849 14.818 20H9.182C9.38566 20.5849 9.76648 21.0917 10.2715 21.4502C10.7765 21.8086 11.3807 22.0008 12 22Z" fill="#0E47CB"/></svg>
                    <span class="notification-dot"></span>
                </a>
                <div class="user-profile">
                    <button id="profile-button" :class="{ 'active': isDropdownOpen }" aria-haspopup="true" aria-expanded="false" aria-controls="profile-menu" aria-label="Account options" @click="toggleDropdown()">
                        <img :src="require('@/assets/images/avatar.png')" alt="Profile picture of Stella White">
                        <span>Stella White</span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Icon / chevron-down"><path id="icon" d="M10.862 5.52864L7.99998 8.39064L5.13798 5.52864L4.19531 6.47131L7.99998 10.276L11.8046 6.47131L10.862 5.52864Z" fill="#666666"/></g></svg>
                    </button>
                    <ul id="profile-menu" class="dropdown-menu" role="menu" aria-labelledby="profile-button" :class="{ 'hidden': !isDropdownOpen }">
                        <li role="none"><a href="#profile" role="menuitem">Profile</a></li>
                        <li role="none"><a href="#settings" role="menuitem">Settings</a></li>
                        <li role="none"><a href="#logout" role="menuitem">Logout</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: "NavigationLayout",
    props: {
    },
    data() {
        return {
            isDropdownOpen: false,
        };
    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/assets/styles/layout/navigation.scss";
</style>
